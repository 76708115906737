import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import React, { memo } from "react";
import "./i18n";
import { Routes } from "./routes/Routes";
import { componentsTheme, baseTheme, typographyTheme } from "./themes";
import { DownloadContextProvider } from "./contexts/DownloadContext/DownloadContext";
import { Dialogs } from "./components/Dialogs/Dialogs";
import { DialogsContextProvider } from "./contexts/DialogsContext/DialogsContext";

const App = memo(() => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={baseTheme}>
        <ThemeProvider theme={componentsTheme}>
          <ThemeProvider theme={typographyTheme}>
            <CssBaseline />
            <Router>
              <DialogsContextProvider>
                <DownloadContextProvider>
                  <Dialogs>
                    <Routes />
                  </Dialogs>
                </DownloadContextProvider>
              </DialogsContextProvider>
            </Router>
          </ThemeProvider>
        </ThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export default App;
