import React, { memo, useCallback, useContext } from "react";
import { useStyles } from "./styles";
import { CustomGrid } from "../../library/custom/CustomGrid/CustomGrid";
import { TyxitIcon } from "../../library/icons/TyxitIcon";
import { CustomTypography } from "../../library/custom/CustomTypography/CustomTypography";
import { DownloadContext } from "../../contexts/DownloadContext/DownloadContext";
import { UserPlatform } from "../../contexts/DownloadContext/types";
import { DownloadForDarwin } from "../../components/Downloading/DownloadForDarwin/DownloadForDarwin";
import { DownloadForWindows } from "../../components/Downloading/DownloadForWindows/DownloadForWindows";
import { AlsoAvailableForDarwin } from "../../components/Downloading/AlsoAvailableForDarwin/AlsoAvailableForDarwin";
import { AlsoAvailableForWindows } from "../../components/Downloading/AlsoAvailableForWindows/AlsoAvailableForWindows";
import { CustomButton } from "../../library/custom/CustomButton/CustomButton";
import { DialogEnum } from "../../contexts/DialogsContext/types";
import { DialogsContext } from "../../contexts/DialogsContext/DialogsContext";
import { CustomScrollbar } from "../../library/custom/CustomScrollbar/CustomScrollbar";

const DownloadingPage = memo(() => {
  const {
    values: { userPlatform },
  } = useContext(DownloadContext);
  const {
    actions: { onShowDialog },
  } = useContext(DialogsContext);

  const classes = useStyles();

  const handleOpenMacChipExplainingDialog = useCallback(() => {
    onShowDialog(DialogEnum.macChipArchitectureDialog);
  }, []);

  return (
    <CustomGrid container height="100%" width="100%">
      <CustomScrollbar autoHeight autoHeightMax="100vh">
        <CustomGrid
          container
          alignItems="center"
          justifyContent="space-between"
          direction="column"
          height="100%"
          width="100%"
          gap={11.25}
          className={classes.content}
        >
          <TyxitIcon className={classes.tyxitIcon} />
          <CustomGrid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <CustomGrid
              item
              container
              flex={1}
              gap={4.5}
              direction="column"
              alignItems={"center"}
              justifyContent="center"
              className={classes.downloadTitle}
            >
              <CustomGrid item>
                <CustomTypography
                  variant="h2"
                  display="inline"
                  fontWeight="400"
                  nameSpace="downloading"
                  translation="downloadingPageTitle.title"
                />
                <CustomTypography
                  variant="h2"
                  display="inline"
                  nameSpace="downloading"
                  translation="downloadingPageTitle.tyxit"
                />
                <CustomTypography
                  variant="h2"
                  display="inline"
                  fontWeight="400"
                  nameSpace="downloading"
                  translation="downloadingPageTitle.platform"
                />
              </CustomGrid>
              {userPlatform === UserPlatform.windows && <DownloadForWindows />}
              {userPlatform === UserPlatform.darwin && <DownloadForDarwin />}
            </CustomGrid>
          </CustomGrid>
          <CustomGrid
            item
            container
            direction="column"
            alignItems="center"
            position="relative"
          >
            {userPlatform === UserPlatform.windows && <AlsoAvailableForDarwin />}
            {userPlatform === UserPlatform.darwin && (
              <>
                <CustomButton
                  variant="contained"
                  nameSpace="downloading"
                  translation="macChipHint"
                  color="secondary"
                  size="small"
                  onClick={handleOpenMacChipExplainingDialog}
                  className={classes.macosChipExplainingButton}
                />
                <AlsoAvailableForWindows />
              </>
            )}
          </CustomGrid>
        </CustomGrid>
      </CustomScrollbar>
    </CustomGrid>
  );
});

export { DownloadingPage };
