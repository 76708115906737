import { createTheme, Theme } from "@mui/material";
import { rem } from "../utils/css/rem";

const componentsTheme = (theme: Theme): Theme =>
  createTheme({
    ...theme,
    components: {
      MuiSvgIcon: {
        defaultProps: {
          fill: "none",
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: rem(8),
            textTransform: "none",
            padding: rem(6, 16),
            "& .MuiTypography-root": {
              fontSize: rem(16),
              lineHeight: rem(22),
            },
          },
        },
        variants: [
          {
            props: {
              size: "medium",
            },
            style: {
              height: rem(52),
            },
          },
          {
            props: {
              size: "small",
            },
            style: {
              height: rem(40),
            },
          },
          {
            props: {
              color: "primary",
            },
            style: {
              backgroundColor: theme.palette.buttons.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.buttons.primary.hover,
              },
              "&:active": {
                backgroundColor: theme.palette.buttons.primary.active,
              },
              "&.Mui-disabled": {
                background: theme.palette.buttons.primary.disabled,
              },
            },
          },
          {
            props: {
              color: "secondary",
            },
            style: {
              backgroundColor: theme.palette.buttons.secondary.main,
              color: theme.palette.text.primary,
              "&:hover": {
                backgroundColor: theme.palette.buttons.secondary.hover,
              },
              "&:active": {
                backgroundColor: theme.palette.buttons.secondary.active,
              },
              "&.Mui-disabled": {
                background: theme.palette.buttons.secondary.disabled,
              },
            },
          },
          {
            props: {
              color: "black",
            },
            style: {
              color: theme.palette.text.contrast,
              backgroundColor: theme.palette.buttons.black.main,
              "&:hover": {
                backgroundColor: theme.palette.buttons.black.hover,
              },
              "&:active": {
                backgroundColor: theme.palette.buttons.black.active,
              },
              "&.Mui-disabled": {
                background: theme.palette.buttons.black.disabled,
              },
            },
          },
        ],
      },
      MuiLink: {
        defaultProps: {
          underline: "none",
        },
        variants: [
          {
            props: {
              color: "primary",
            },
            style: {
              cursor: "pointer",
              color: theme.palette.link.primary.main,
              "&:hover": {
                color: theme.palette.link.primary.hover,
              },
              "&:active": {
                color: theme.palette.link.primary.active,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            color: theme.palette.primary.main,
          },
        },
      },
      MuiGrid: {
        defaultProps: {
          flexWrap: "nowrap",
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            backdropFilter: `blur(${rem(5)})`,
            boxShadow: `${rem(0, 4, 60, 0)} #00000099`,
            border: `${rem(1)} solid #FFFFFF0D`,
          },
          paper: {
            borderRadius: rem(16),
            backgroundImage: "none",
            maxWidth: "none",
            maxHeight: "none",
            margin: rem(30),
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
          disableTouchRipple: true,
        },
      },
    },
  });

export { componentsTheme };
