/* eslint-disable max-len */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React, { memo } from "react";

const AppleIcon = memo((props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 15 18" {...props}>
      <g clipPath="url(#clip0_4638_18188)">
        <path
          d="M14.1858 6.1362C14.0814 6.2172 12.2382 7.2558 12.2382 9.5652C12.2382 12.2364 14.5836 13.1814 14.6538 13.2048C14.643 13.2624 14.2812 14.499 13.4172 15.759C12.6468 16.8678 11.8422 17.9748 10.6182 17.9748C9.3942 17.9748 9.0792 17.2638 7.6662 17.2638C6.2892 17.2638 5.7996 17.9982 4.68 17.9982C3.5604 17.9982 2.7792 16.9722 1.881 15.7122C0.8406 14.2326 0 11.934 0 9.7524C0 6.2532 2.2752 4.3974 4.5144 4.3974C5.7042 4.3974 6.696 5.1786 7.443 5.1786C8.154 5.1786 9.2628 4.3506 10.6164 4.3506C11.1294 4.3506 12.9726 4.3974 14.1858 6.1362ZM9.9738 2.8692C10.5336 2.205 10.9296 1.2834 10.9296 0.3618C10.9296 0.234 10.9188 0.1044 10.8954 0C9.9846 0.0342 8.901 0.6066 8.2476 1.3644C7.7346 1.9476 7.2558 2.8692 7.2558 3.8034C7.2558 3.9438 7.2792 4.0842 7.29 4.1292C7.3476 4.14 7.4412 4.1526 7.5348 4.1526C8.352 4.1526 9.3798 3.6054 9.9738 2.8692Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4638_18188">
          <rect width="14.652" height="18" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});

export { AppleIcon };
