import { FirebaseApp, initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";
import { FirebaseStorage, getStorage } from "firebase/storage";
import { Analytics, getAnalytics } from "firebase/analytics";
import { FIREBASE_CONFIG } from "../../const/config";

class FirebaseService {
  private app: FirebaseApp = initializeApp(FIREBASE_CONFIG);

  readonly db: Firestore = getFirestore();

  readonly storage: FirebaseStorage = getStorage();

  readonly analytics: Analytics = getAnalytics();
}

export default new FirebaseService();
