import { makeStyles } from "@mui/styles";
import { rem } from "../../../utils/css/rem";

const useStyles = makeStyles(() => ({
  button: {
    width: rem(234),
  },
}));

export { useStyles };
