import React, { memo, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { DialogEnum, DialogsContextData } from "./types";

export const DialogsContext = React.createContext<DialogsContextData>({
  values: {
    state: {
      [DialogEnum.macChipArchitectureDialog]: false,
    },
  },
  actions: {
    onCloseDialog: () => {},
    onShowDialog: () => {},
  },
});

export const DialogsContextProvider = memo(({ children }: PropsWithChildren<any>) => {
  const [state, setState] = useState({
    [DialogEnum.macChipArchitectureDialog]: false,
  });

  const handleShowDialog = useCallback((dialog: DialogEnum) => {
    setState((prevState) => ({ ...prevState, [dialog]: true }));
  }, []);

  const handleCloseDialog = useCallback((dialog: DialogEnum) => {
    setState((prevState) => ({ ...prevState, [dialog]: false }));
  }, []);

  const value = useMemo<DialogsContextData>(
    () => ({
      values: {
        state,
      },
      actions: {
        onShowDialog: handleShowDialog,
        onCloseDialog: handleCloseDialog,
      },
    }),
    [state, handleShowDialog, handleCloseDialog],
  );

  return <DialogsContext.Provider value={value}>{children}</DialogsContext.Provider>;
});
