import React, { memo } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import { AppRedirectPage } from "../containers/AppRedirectPage/AppRedirectPage";
import { DownloadingPage } from "../containers/DownloadingPage/DownloadingPage";
import { NotFound } from "../containers/NotFound/NotFound";

const Routes = memo(() => {
  return (
    <Switch>
      <Route path="/download" element={<DownloadingPage />} />
      <Route path="/*" element={<AppRedirectPage />} />
      <Route path="/not-found" element={<NotFound />} />
    </Switch>
  );
});

export { Routes };
