import React, { memo } from "react";
import { useTranslation } from "../../../hooks/useTranslation";
import { TranslationProps } from "./types";

const Translation = memo(({ nameSpace, translation }: TranslationProps) => {
  const { t } = useTranslation(nameSpace);
  return <>{t(translation)}</>;
});

export { Translation };
