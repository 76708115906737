import React, { memo, useCallback, useContext } from "react";
import { CustomButton } from "../../../library/custom/CustomButton/CustomButton";
import { CustomGrid } from "../../../library/custom/CustomGrid/CustomGrid";
import { AppleIcon } from "../../../library/icons/AppleIcon";
import { useStyles } from "./styles";
import { DownloadContext } from "../../../contexts/DownloadContext/DownloadContext";
import { AppTargetPlatform } from "../../../contexts/DownloadContext/types";

const DownloadForDarwin = memo(() => {
  const {
    actions: { onRequestDownload },
  } = useContext(DownloadContext);

  const classes = useStyles();

  const handleClickMacIntelDownload = useCallback(() => {
    onRequestDownload({ target: AppTargetPlatform.darwinX64 });
  }, []);

  const handleClickMacAppleDownload = useCallback(() => {
    onRequestDownload({ target: AppTargetPlatform.darwinArm64 });
  }, []);

  return (
    <CustomGrid container gap={6.25} width="fit-content">
      <CustomButton
        color="black"
        variant="contained"
        nameSpace="downloading"
        translation="platforms.macIntel"
        startIcon={<AppleIcon />}
        onClick={handleClickMacIntelDownload}
        className={classes.button}
      />
      <CustomButton
        color="black"
        variant="contained"
        nameSpace="downloading"
        translation="platforms.macApple"
        startIcon={<AppleIcon />}
        onClick={handleClickMacAppleDownload}
        className={classes.button}
      />
    </CustomGrid>
  );
});

export { DownloadForDarwin };
