import React, { memo } from "react";
import { CommonImageProps } from "./types";

const CustomImage = memo(
  ({
    src,
    ...rest
  }: CommonImageProps & React.HTMLAttributes<HTMLImageElement>): JSX.Element => {
    return <img src={src} alt="" {...rest} />;
  },
);

export { CustomImage };
