import { ReleaseModelType } from "../models/releases";
import { ReleasesRepository } from "../repository";

const findLatestRelease = async (): Promise<ReleaseModelType | null> => {
  const docs = await ReleasesRepository.find({
    orderBy: "publicationDate",
    orderDirection: "desc",
    limit: 1,
  });
  const latestRelease = docs.docs.shift()?.data() as ReleaseModelType;

  return latestRelease || null;
};

export { findLatestRelease };
