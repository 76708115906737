/* eslint-disable max-len */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React, { memo } from "react";

const TyxitIcon = memo((props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 193 28" {...props}>
      <path
        d="M156.844 6.51153C157.799 6.68517 158.566 7.02577 159.145 7.53333C159.737 8.0409 160.033 8.8156 160.033 9.85745C160.033 11.3 159.522 12.3285 158.499 12.9429C157.476 13.544 156.043 13.8445 154.199 13.8445H150V0H153.654C157.503 0 159.428 1.17541 159.428 3.52624C159.428 4.34102 159.179 5.00887 158.681 5.52979C158.183 6.03735 157.57 6.3646 156.844 6.51153ZM152.665 1.94344V5.69007H154.3C155.04 5.69007 155.626 5.52979 156.056 5.20922C156.487 4.8753 156.702 4.38777 156.702 3.74663C156.702 3.07878 156.473 2.61129 156.016 2.34415C155.572 2.07701 154.872 1.94344 153.916 1.94344H152.665ZM154.219 11.861C155.202 11.861 155.949 11.7141 156.46 11.4202C156.985 11.1264 157.247 10.572 157.247 9.75727C157.247 8.96921 156.998 8.40154 156.5 8.05426C156.016 7.70698 155.35 7.53333 154.502 7.53333H152.665V11.861H154.219Z"
        fill="currentColor"
      />
      <path
        d="M170.476 0L170.193 1.96348H165.045V5.83032H169.527V7.7938H165.045V11.861H170.557V13.8445H162.381V0H170.476Z"
        fill="currentColor"
      />
      <path
        d="M182.049 0L181.766 2.10372H178.132V13.8445H175.448V2.10372H171.693V0H182.049Z"
        fill="currentColor"
      />
      <path
        d="M190.174 13.8445L189.306 10.6388H184.683L183.815 13.8445H181.049L185.389 0H188.68L193 13.8445H190.174ZM185.208 8.63528H188.761L186.984 2.02358L185.208 8.63528Z"
        fill="currentColor"
      />
      <path
        d="M58.6977 0.000473624H49.2614C49.2082 0.00104881 49.1562 0.0156776 49.1107 0.0428417C49.0652 0.0700059 49.0279 0.108716 49.0026 0.154953L43.6669 9.94511C43.5112 10.2303 43.371 10.5236 43.2471 10.8237C43.2146 10.903 43.159 10.971 43.0872 11.0188C43.0154 11.0666 42.9309 11.0922 42.8443 11.0922C42.7578 11.0922 42.6733 11.0666 42.6015 11.0188C42.5297 10.971 42.4741 10.903 42.4416 10.8237C42.3176 10.5236 42.1775 10.2304 42.0218 9.94511L36.691 0.154953C36.6653 0.107997 36.6272 0.0688199 36.5808 0.0416102C36.5343 0.0144004 36.4813 0.000181494 36.4273 0.000473624H26.9568C26.8945 0.0010126 26.8341 0.0222434 26.7854 0.0607608C26.7367 0.0992782 26.7025 0.15285 26.6883 0.212883C26.674 0.295649 26.6933 0.380659 26.742 0.449431L37.2865 16.8629C38.215 18.3053 38.7063 19.9805 38.7022 21.6904V27.7055C38.7022 27.7836 38.7336 27.8585 38.7894 27.9137C38.8453 27.9689 38.921 27.9999 39 27.9999H46.6936C46.7726 27.9999 46.8483 27.9689 46.9041 27.9137C46.96 27.8585 46.9914 27.7836 46.9914 27.7055V21.6663C46.9873 19.9564 47.4785 18.2812 48.4071 16.8388L58.9711 0.425293C59.0044 0.373306 59.0192 0.311758 59.013 0.250496C59.0068 0.189233 58.98 0.131795 58.9369 0.0873685C58.9051 0.0575612 58.8675 0.0344815 58.8264 0.0195407C58.7852 0.00459993 58.7415 -0.00188801 58.6977 0.000473624V0.000473624Z"
        fill="currentColor"
      />
      <path
        d="M110.571 0.000457764H102.326C102.161 0.000457764 102.028 0.1323 102.028 0.294935V27.7054C102.028 27.8681 102.161 27.9999 102.326 27.9999H110.571C110.735 27.9999 110.869 27.8681 110.869 27.7054V0.294935C110.869 0.1323 110.735 0.000457764 110.571 0.000457764Z"
        fill="currentColor"
      />
      <path
        d="M142.707 0.000457764H118.396C118.317 0.000457764 118.241 0.0314829 118.186 0.0867081C118.13 0.141933 118.098 0.216835 118.098 0.294935V6.92308C118.098 7.00118 118.13 7.07609 118.186 7.13131C118.241 7.18654 118.317 7.21756 118.396 7.21756H125.719C125.834 7.21756 125.944 7.26283 126.026 7.3434C126.107 7.42398 126.153 7.53326 126.153 7.64721V27.7054C126.153 27.7811 126.182 27.8539 126.235 27.9087C126.288 27.9635 126.36 27.9962 126.436 27.9999H134.647C134.726 27.9999 134.802 27.9689 134.858 27.9137C134.914 27.8584 134.945 27.7835 134.945 27.7054V7.64721C134.945 7.53326 134.991 7.42398 135.072 7.3434C135.154 7.26283 135.264 7.21756 135.38 7.21756H142.702C142.781 7.21756 142.857 7.18654 142.913 7.13131C142.969 7.07609 143 7.00118 143 6.92308V0.294935C143 0.217664 142.969 0.143485 142.915 0.0883965C142.86 0.033308 142.785 0.0017245 142.707 0.000457764V0.000457764Z"
        fill="currentColor"
      />
      <path
        d="M24.6087 0.000543115H0.297785C0.218807 0.000543115 0.143064 0.0315683 0.0872191 0.0867935C0.0313736 0.142019 0 0.21692 0 0.29502L0 6.92317C0 7.00127 0.0313736 7.07617 0.0872191 7.1314C0.143064 7.18662 0.218807 7.21765 0.297785 7.21765H7.62035C7.73558 7.21765 7.84609 7.26291 7.92757 7.34349C8.00905 7.42406 8.05483 7.53334 8.05483 7.64729V27.7055C8.05483 27.7836 8.0862 27.8585 8.14205 27.9138C8.19789 27.969 8.27363 28 8.35261 28H16.5588C16.6377 28 16.7135 27.969 16.7693 27.9138C16.8252 27.8585 16.8566 27.7836 16.8566 27.7055V7.64729C16.8566 7.53334 16.9023 7.42406 16.9838 7.34349C17.0653 7.26291 17.1758 7.21765 17.291 7.21765H24.6136C24.6926 7.21765 24.7683 7.18662 24.8242 7.1314C24.88 7.07617 24.9114 7.00127 24.9114 6.92317V0.29502C24.9114 0.255939 24.9035 0.217248 24.8883 0.181202C24.873 0.145156 24.8506 0.112477 24.8224 0.0850679C24.7943 0.0576592 24.7609 0.0360697 24.7242 0.0215579C24.6875 0.00704618 24.6482 -9.7555e-05 24.6087 0.000543115V0.000543115Z"
        fill="currentColor"
      />
      <path
        d="M85.5715 17.6353C83.5553 15.6888 80.8494 14.5993 78.0317 14.5993C75.2141 14.5993 72.5081 15.6888 70.4919 17.6353C67.929 20.1407 60.5332 27.5751 60.5332 27.5751C60.294 27.8116 60.377 27.9999 60.7139 27.9999H70.7848C70.8484 27.9998 70.9096 27.9756 70.9557 27.9323L75.8667 23.1048C76.1484 22.8174 76.4857 22.5888 76.8584 22.4327C77.2312 22.2767 77.6318 22.1962 78.0366 22.1962C78.4414 22.1962 78.8421 22.2767 79.2148 22.4327C79.5876 22.5888 79.9248 22.8174 80.2065 23.1048L85.1175 27.9323C85.1637 27.9756 85.2248 27.9998 85.2884 27.9999H95.3789C95.7157 27.9999 95.7939 27.8068 95.5595 27.5751C95.5547 27.5751 88.1344 20.1407 85.5715 17.6353Z"
        fill="currentColor"
      />
      <path
        d="M95.374 0.000457764H85.2835C85.2199 0.00058102 85.1588 0.0247628 85.1127 0.0680427L80.2017 4.89554C79.9199 5.18299 79.5827 5.41153 79.2099 5.56761C78.8372 5.72369 78.4365 5.80411 78.0317 5.80411C77.6269 5.80411 77.2263 5.72369 76.8535 5.56761C76.4808 5.41153 76.1435 5.18299 75.8618 4.89554L70.9508 0.0680427C70.9059 0.0258892 70.8467 0.00179352 70.7848 0.000457764L60.6895 0.000457764C60.3526 0.000457764 60.2745 0.193558 60.5088 0.425277C60.5088 0.425277 67.9241 7.85962 70.4919 10.3651C72.5081 12.3115 75.2141 13.4011 78.0317 13.4011C80.8494 13.4011 83.5553 12.3115 85.5715 10.3651C88.1344 7.85962 95.5547 0.425277 95.5547 0.425277C95.789 0.193558 95.7109 0.000457764 95.374 0.000457764Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});

export { TyxitIcon };
