import React, { forwardRef, memo } from "react";
import { Grid, GridProps } from "@mui/material";

const CustomGrid = memo(
  forwardRef<HTMLDivElement, GridProps>((props, ref) => {
    return <Grid ref={ref} {...props} />;
  }),
);

export { CustomGrid };
