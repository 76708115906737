import React, { memo, useCallback, useContext, useEffect, useMemo } from "react";
import { useStyles } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { CustomBox } from "../../library/custom/CustomBox/CustomBox";
import { CustomGrid } from "../../library/custom/CustomGrid/CustomGrid";
import { TyxitIcon } from "../../library/icons/TyxitIcon";
import { CustomTypography } from "../../library/custom/CustomTypography/CustomTypography";
import { CustomButton } from "../../library/custom/CustomButton/CustomButton";
import { CustomDivider } from "../../library/custom/CustomDivider/CustomDivider";
import { DownloadContext } from "../../contexts/DownloadContext/DownloadContext";
import { UserPlatform } from "../../contexts/DownloadContext/types";
import { DownloadForDarwin } from "../../components/Downloading/DownloadForDarwin/DownloadForDarwin";
import { DownloadForWindows } from "../../components/Downloading/DownloadForWindows/DownloadForWindows";
import { AlsoAvailableForDarwin } from "../../components/Downloading/AlsoAvailableForDarwin/AlsoAvailableForDarwin";
import { AlsoAvailableForWindows } from "../../components/Downloading/AlsoAvailableForWindows/AlsoAvailableForWindows";
import { APP_PROTOCOL } from "../../const/config";
import { DialogsContext } from "../../contexts/DialogsContext/DialogsContext";
import { DialogEnum } from "../../contexts/DialogsContext/types";
import { CustomScrollbar } from "../../library/custom/CustomScrollbar/CustomScrollbar";

const AppRedirectPage = memo(() => {
  const { "*": code } = useParams();
  const navigate = useNavigate();

  const {
    values: { userPlatform },
  } = useContext(DownloadContext);
  const {
    actions: { onShowDialog },
  } = useContext(DialogsContext);

  const classes = useStyles();

  const isCodeValid = useMemo(() => {
    if (!code) {
      return false;
    }
    return /^[A-Za-z0-9]{3}-[A-Za-z0-9]{3}$/.test(code);
  }, [code]);

  const handleOpenApp = useCallback(() => {
    if (!code || !isCodeValid) {
      return;
    }

    window.location.replace(`${APP_PROTOCOL}://./join?code=${code}`);
  }, [isCodeValid, code]);

  const handleOpenMacChipExplainingDialog = useCallback(() => {
    onShowDialog(DialogEnum.macChipArchitectureDialog);
  }, []);

  useEffect(() => {
    handleOpenApp();
  }, [handleOpenApp]);

  useEffect(() => {
    if (!isCodeValid) {
      navigate("/not-found");
      return;
    }
  }, [isCodeValid]);

  return (
    <CustomGrid container height="100%" width="100%">
      <CustomScrollbar autoHeight autoHeightMax="100vh">
        <CustomGrid
          container
          alignItems="center"
          justifyContent="space-between"
          direction="column"
          height="100%"
          width="100%"
          gap={11.25}
          className={classes.content}
        >
          <TyxitIcon className={classes.tyxitIcon} />
          <CustomGrid
            item
            container
            flex={1}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <CustomBox>
              <CustomTypography
                variant="h2"
                fontWeight={400}
                nameSpace="appRedirect"
                translation="joinMeeting.click"
                display="inline"
              />
              <CustomTypography
                variant="h2"
                nameSpace="appRedirect"
                translation="joinMeeting.openApp"
                display="inline"
              />
              <CustomTypography
                variant="h2"
                fontWeight={400}
                nameSpace="appRedirect"
                translation="joinMeeting.inBrowserDialog"
                display="inline"
              />
            </CustomBox>
            <CustomBox className={classes.description}>
              <CustomTypography
                variant="h2"
                fontWeight={400}
                nameSpace="appRedirect"
                translation="openDialog.ifYouDontSeeJoinMeeting"
                display="inline"
              />
              <CustomTypography
                variant="h2"
                nameSpace="appRedirect"
                translation="openDialog.joinMeeting"
                display="inline"
              />
            </CustomBox>
            <CustomButton
              variant="contained"
              nameSpace="appRedirect"
              translation="joinMeetingButton"
              onClick={handleOpenApp}
              className={classes.button}
            />
            <CustomDivider className={classes.divider} />
            <CustomBox className={classes.downloadTitle}>
              <CustomTypography
                variant="h2"
                display="inline"
                fontWeight="400"
                nameSpace="downloading"
                translation="appRedirectTitle.title"
              />
              <CustomTypography
                variant="h2"
                display="inline"
                nameSpace="downloading"
                translation="appRedirectTitle.tyxit"
              />
              <CustomTypography
                variant="h2"
                display="inline"
                fontWeight="400"
                nameSpace="downloading"
                translation="appRedirectTitle.continue"
              />
            </CustomBox>
            {userPlatform === UserPlatform.windows && <DownloadForWindows />}
            {userPlatform === UserPlatform.darwin && <DownloadForDarwin />}
          </CustomGrid>
          <CustomGrid
            container
            direction="column"
            alignItems="center"
            gap={3.5}
            position="relative"
          >
            {userPlatform === UserPlatform.windows && <AlsoAvailableForDarwin />}
            {userPlatform === UserPlatform.darwin && (
              <>
                <CustomButton
                  variant="contained"
                  nameSpace="downloading"
                  translation="macChipHint"
                  color="secondary"
                  size="small"
                  onClick={handleOpenMacChipExplainingDialog}
                  className={classes.openDialogButton}
                />
                <AlsoAvailableForWindows />
              </>
            )}
          </CustomGrid>
        </CustomGrid>
      </CustomScrollbar>
    </CustomGrid>
  );
});

export { AppRedirectPage };
