import React, { memo, useCallback } from "react";
import { CustomTypography } from "../../library/custom/CustomTypography/CustomTypography";
import { CustomGrid } from "../../library/custom/CustomGrid/CustomGrid";
import { useStyles } from "./styles";
import { TyxitIcon } from "../../library/icons/TyxitIcon";
import { CustomImage } from "../../library/custom/CustomImage/CustomImage";
import { CustomButton } from "../../library/custom/CustomButton/CustomButton";
import { HOME_URL } from "../../const/config";
import { openUrl } from "../../utils/window";

const NotFound = memo(() => {
  const classes = useStyles();

  const handleClickGoHome = useCallback(() => {
    openUrl({
      url: HOME_URL,
      target: "_self",
    });
  }, []);

  return (
    <CustomGrid container className={classes.root}>
      <TyxitIcon className={classes.tyxitIcon} />
      <CustomGrid
        container
        alignItems="center"
        direction="column"
        justifyContent="center"
        gap={4.5}
      >
        <CustomImage src="images/headphones.png" className={classes.headphones} />
        <CustomTypography
          variant="h2"
          fontWeight={400}
          nameSpace="notFound"
          translation="title"
          className={classes.title}
        />
        <CustomButton
          color="primary"
          variant="contained"
          nameSpace="notFound"
          translation="goHome"
          onClick={handleClickGoHome}
          className={classes.button}
        />
      </CustomGrid>
    </CustomGrid>
  );
});

export { NotFound };
