import React, { forwardRef, memo } from "react";
import { Box } from "@mui/material";
import { CustomBoxProps } from "./types";

const CustomBox = memo(
  forwardRef<HTMLDivElement, CustomBoxProps>(({ src, ...props }, ref) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Box ref={ref} src={src} {...props} />;
  }),
);

export { CustomBox };
