import React, { memo, PropsWithChildren } from "react";
import { MacChipArchitectureExplainingDialog } from "./MacChipArchitectureExplainingDialog/MacChipArchitectureExplainingDialog";

const Dialogs = memo(({ children }: PropsWithChildren<any>) => {
  return (
    <>
      <MacChipArchitectureExplainingDialog />
      {children}
    </>
  );
});

export { Dialogs };
