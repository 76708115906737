import { makeStyles } from "@mui/styles";
import { rem } from "../../../utils/css/rem";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.contrast,
    color: theme.palette.text.contrast,
    padding: rem(52),
    width: rem(508),
    maxHeight: "90vh",
    height: rem(800),
  },
  closeButton: {
    position: "absolute",
    top: rem(6),
    right: rem(6),
    color: theme.palette.text.contrast,
  },
  scrollbar: {
    width: `calc(100% + ${rem(24)}) !important`,
    "& #scrollbar-content": {
      padding: rem(0, 24, 0, 0),
    },
  },
  content: {
    height: "fit-content",
  },
  step: {
    whiteSpace: "break-spaces",
  },
  img: {
    width: rem(404),
    height: rem(218),
    borderRadius: rem(12),
    objectFit: "contain",
  },
}));

export { useStyles };
