import React, { forwardRef, memo } from "react";
import { Typography } from "@mui/material";
import { CustomTypographyProps } from "./types";
import { Translation } from "../../common/Translation/Translation";

const CustomTypography = memo(
  forwardRef<any, CustomTypographyProps>(
    ({ nameSpace, translation, children, ...props }, ref) => {
      return (
        <Typography ref={ref} {...props}>
          {translation && nameSpace && (
            <Translation nameSpace={nameSpace} translation={translation} />
          )}
          {children}
        </Typography>
      );
    },
  ),
);

export { CustomTypography };
