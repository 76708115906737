import {
  Firestore,
  FieldPath,
  WhereFilterOp,
  QuerySnapshot,
  DocumentData,
  OrderByDirection,
  QueryConstraint,
  collection,
  where,
  query,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import FirebaseService from "../FirebaseService";

class Repository {
  private path: string;

  private db: Firestore;

  constructor({ path }: { path: string }) {
    this.path = path;
    this.db = FirebaseService.db;
  }

  find({
    conditions,
    orderBy: orderByField,
    orderDirection,
    limit: limitValue,
  }: {
    conditions?: [fieldPath: string | FieldPath, opStr: WhereFilterOp, value: unknown][];
    orderBy?: string;
    orderDirection?: OrderByDirection;
    limit?: number;
  }): Promise<QuerySnapshot<DocumentData>> {
    const constraints: Array<QueryConstraint | null> = conditions
      ? conditions.map(([path, filterOp, val]) => where(path, filterOp, val))
      : [];
    constraints.push(orderByField ? orderBy(orderByField, orderDirection) : null);
    constraints.push(limitValue ? limit(limitValue) : null);
    const queryConstraints: QueryConstraint[] = constraints.filter(
      (constraint): constraint is QueryConstraint => constraint !== null,
    );

    const q = query(collection(this.db, this.path), ...queryConstraints);
    return getDocs(q);
  }
}

export { Repository };
