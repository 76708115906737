import { createTheme, Theme } from "@mui/material";

const typographyTheme = (theme: Theme): Theme =>
  createTheme({
    ...theme,
    typography: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      htmlFontSize: 16,
      h1: {
        fontSize: "34px",
        lineHeight: "40px",
        fontWeight: 700,
      },
      h2: {
        fontSize: "26px",
        lineHeight: "32px",
        fontWeight: 700,
      },
      body1: {
        fontSize: "18px",
        lineHeight: "24px",
      },
      body2: {
        fontSize: "16px",
        lineHeight: "24px",
      },
      body3: {
        fontSize: "14px",
        lineHeight: "20px",
      },
      system: {
        fontSize: "12px",
        lineHeight: "14px",
      },
    },
  });

export { typographyTheme };
