import { useCallback, useState } from "react";

type ReturnT = {
  state: boolean;
  onToggle: () => void;
  onSetValue: (data: { value: boolean }) => void;
};

export const useToggle = ({ initial }: { initial: boolean }): ReturnT => {
  const [state, setState] = useState(initial);

  const handleToggle = useCallback(() => {
    setState((prevState) => !prevState);
  }, []);

  const handleSetValue = useCallback(({ value }: { value: boolean }) => {
    setState(value);
  }, []);

  return {
    state,
    onToggle: handleToggle,
    onSetValue: handleSetValue,
  };
};
