import React, { memo, useCallback, useContext } from "react";
import { CustomTypography } from "../../../library/custom/CustomTypography/CustomTypography";
import { CustomLink } from "../../../library/custom/CustomLink/CustomLink";
import { CustomGrid } from "../../../library/custom/CustomGrid/CustomGrid";
import { AppTargetPlatform } from "../../../contexts/DownloadContext/types";
import { DownloadContext } from "../../../contexts/DownloadContext/DownloadContext";

const AlsoAvailableForWindows = memo(() => {
  const {
    actions: { onRequestDownload },
  } = useContext(DownloadContext);

  const handleClickWinDownload = useCallback(() => {
    onRequestDownload({ target: AppTargetPlatform.windowsX64 });
  }, []);

  return (
    <CustomGrid item container width="fit-content">
      <CustomTypography
        whiteSpace="break-spaces"
        nameSpace="downloading"
        translation="alsoAvailable"
      />
      <CustomLink color="primary">
        <CustomTypography
          nameSpace="downloading"
          color="inherit"
          translation="platforms.windows"
          onClick={handleClickWinDownload}
        />
      </CustomLink>
    </CustomGrid>
  );
});

export { AlsoAvailableForWindows };
