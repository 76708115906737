import { rem } from "../../utils/css/rem";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: "100vh",
    backgroundImage: "url('/images/download-page-bg.png')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: theme.palette.background.default,
    padding: rem(40, 40, 60, 40),
  },
  tyxitIcon: {
    width: rem(138),
    height: rem(20),
    alignSelf: "flex-start",
  },
  description: {
    margin: rem(16, 0, 40),
  },
  button: {
    width: rem(234),
  },
  divider: {
    width: "100%",
    maxWidth: rem(984),
    margin: rem(64, 0, 60),
  },
  downloadTitle: {
    margin: rem(0, 0, 36, 0),
  },
  openDialogButton: {
    position: "absolute",
    top: rem(-70),
  },
}));

export { useStyles };
