import React, { memo, useCallback, useContext } from "react";
import { CustomGrid } from "../../../library/custom/CustomGrid/CustomGrid";
import { CustomButton } from "../../../library/custom/CustomButton/CustomButton";
import { useStyles } from "./styles";
import { AppTargetPlatform } from "../../../contexts/DownloadContext/types";
import { DownloadContext } from "../../../contexts/DownloadContext/DownloadContext";
import { WindowsIcon } from "../../../library/icons/WindowsIcon";

const DownloadForWindows = memo(() => {
  const {
    actions: { onRequestDownload },
  } = useContext(DownloadContext);

  const classes = useStyles();

  const handleClickWinDownload = useCallback(() => {
    onRequestDownload({ target: AppTargetPlatform.windowsX64 });
  }, []);

  return (
    <CustomGrid container direction="column" gap={6.75} alignItems="center">
      <CustomButton
        color="black"
        variant="contained"
        nameSpace="downloading"
        translation="platforms.windows"
        startIcon={<WindowsIcon />}
        onClick={handleClickWinDownload}
        className={classes.button}
      />
    </CustomGrid>
  );
});

export { DownloadForWindows };
