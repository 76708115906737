export enum DialogEnum {
  macChipArchitectureDialog = "macChipArchitectureDialog",
}

export type DialogsContextStateType = { [key in DialogEnum]: boolean };

export type DialogsContextData = {
  values: {
    state: DialogsContextStateType;
  };
  actions: {
    onShowDialog: (dialog: DialogEnum) => void;
    onCloseDialog: (dialog: DialogEnum) => void;
  };
};
