import { Button } from "@mui/material";
import React, { memo } from "react";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import { CustomButtonProps } from "./types";

const CustomButton = memo(({ nameSpace, translation, ...props }: CustomButtonProps) => {
  return (
    <Button {...props}>
      <CustomTypography
        display="inline"
        nameSpace={nameSpace}
        translation={translation}
      />
    </Button>
  );
});

export { CustomButton };
