import React, { forwardRef, memo, useCallback } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import clsx from "clsx";
import { CustomBox } from "../CustomBox/CustomBox";
import { useStyles } from "./styles";
import { CustomScrollbarProps } from "./types";

const CustomScrollbar = memo(
  forwardRef(
    (
      { variant = "dark", ...props }: CustomScrollbarProps,
      ref: React.ForwardedRef<Scrollbars>,
    ) => {
      const classes = useStyles();

      const renderTrackHorizontal = useCallback(
        (trackProps: React.ReactPropTypes): JSX.Element => (
          <CustomBox
            id="trackHorizontal"
            className={clsx(classes.track, classes.trackHorizontal)}
            {...trackProps}
          />
        ),
        [],
      );
      const renderTrackVertical = useCallback(
        (trackProps: React.ReactPropTypes): JSX.Element => (
          <CustomBox
            id="trackVertical"
            className={clsx(classes.track, classes.trackVertical)}
            {...trackProps}
          />
        ),
        [],
      );
      const renderThumbVertical = useCallback(
        (thumbProps: React.ReactPropTypes): JSX.Element => (
          <CustomBox
            id="thumbVertical"
            className={clsx(classes.thumb, {
              [classes.lightThumb]: variant === "light",
              [classes.darkThumb]: variant === "dark",
            })}
            {...thumbProps}
          />
        ),
        [],
      );
      const renderThumbHorizontal = useCallback(
        (thumbProps: React.ReactPropTypes): JSX.Element => (
          <CustomBox
            id="thumbHorizontal"
            className={clsx(classes.thumb, {
              [classes.lightThumb]: variant === "light",
              [classes.darkThumb]: variant === "dark",
            })}
            {...thumbProps}
          />
        ),
        [],
      );

      return (
        <Scrollbars
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={(r) => ref?.(r?.view)}
          renderTrackHorizontal={renderTrackHorizontal}
          renderTrackVertical={renderTrackVertical}
          renderThumbVertical={renderThumbVertical}
          renderThumbHorizontal={renderThumbHorizontal}
          renderView={(p) => <div id="scrollbar-content" {...p} />}
          {...props}
        />
      );
    },
  ),
);

export { CustomScrollbar };
