const getUserAgentPlatform = (): "darwin" | "win32" | null => {
  if (navigator.userAgent.includes("Macintosh")) {
    return "darwin";
  } else if (navigator.userAgent.includes("Windows")) {
    return "win32";
  } else {
    return null;
  }
};

export { getUserAgentPlatform };
