import React, { memo } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CloseIcon = memo((props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="12" fill="white" fillOpacity="0.05" />
      <path
        d="M9 9L15.5 15.5"
        stroke="currentColor"
        strokeWidth="1.45455"
        strokeLinecap="round"
      />
      <path
        d="M15.5 9L9 15.5"
        stroke="currentColor"
        strokeWidth="1.45455"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
});

export { CloseIcon };
