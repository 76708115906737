import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppTargetPlatform, DownloadContextData, UserPlatform } from "./types";
import { findLatestRelease } from "../../services/firebase/releases";
import { getUserAgentPlatform } from "../../utils/userAgent";

export const DownloadContext = React.createContext<DownloadContextData>({
  values: {
    userPlatform: null,
  },
  actions: {
    onRequestDownload: () => {},
  },
});

export const DownloadContextProvider = ({
  children,
}: PropsWithChildren<any>): JSX.Element => {
  const [userPlatform, setUserPlatform] = useState<UserPlatform | null>(null);
  const [requestDownload, setRequestDownload] = useState<AppTargetPlatform | null>(null);

  useEffect(() => {
    const platform = getUserAgentPlatform() as UserPlatform;
    if (platform) {
      setUserPlatform(platform);
    }
  }, []);

  useEffect(() => {
    if (!requestDownload) {
      return;
    }

    (async () => {
      const latestRelease = await findLatestRelease();
      if (!latestRelease) {
        return;
      }

      const downloadLink = latestRelease[`${requestDownload}_installer`];
      if (downloadLink) {
        window.location.replace(downloadLink);
      }
      setRequestDownload(null);
    })();
  }, [requestDownload]);

  const handleRequestDownload = useCallback(
    ({ target }: { target: AppTargetPlatform }) => {
      setRequestDownload(target);
    },
    [],
  );

  const value = useMemo(
    () => ({
      values: {
        userPlatform,
      },
      actions: {
        onRequestDownload: handleRequestDownload,
      },
    }),
    [userPlatform, handleRequestDownload],
  );

  return <DownloadContext.Provider value={value}>{children}</DownloadContext.Provider>;
};
