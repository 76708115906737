const openUrl = ({
  url,
  target,
  features,
}: {
  url: string;
  target?: string;
  features?: string;
}) => {
  window.open(url, target, features);
};

export { openUrl };
