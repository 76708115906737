import createPalette from "@mui/material/styles/createPalette";

const palette = createPalette({
  buttons: {
    primary: {
      main: "#FAA603",
      hover: "#FB9600",
      active: "#F18200",
      disabled: "#FFFFFF0D",
    },
    secondary: {
      main: "#9292921A",
      hover: "#92929233",
      active: "#9292924D",
      disabled: "#9292921A",
    },
    black: {
      main: "#0B0D10",
      hover: "#2F3135",
      active: "#4E5055",
      disabled: "#9292921A",
    },
  },
  link: {
    primary: {
      main: "#FAA603",
      hover: "#FB9600",
      active: "#FB9600",
    },
  },
  black: {
    main: "#0B0D10",
    light: "#4E5055",
    dark: "#2F3135",
    contrastText: "#FFF",
  },
  background: {
    default: "#ECECEC",
    contrast: "#0B0D10",
  },
  text: {
    contrast: "#FFFFFF",
  },
});

export { palette };
