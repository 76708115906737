export enum UserPlatform {
  windows = "win32",
  darwin = "darwin",
}

export enum AppTargetPlatform {
  windowsX64 = "win32_x64",
  darwinX64 = "darwin_x64",
  darwinArm64 = "darwin_arm64",
}

export type DownloadContextData = {
  values: {
    userPlatform: UserPlatform | null;
  };
  actions: {
    onRequestDownload: (data: { target: AppTargetPlatform }) => void;
  };
};
