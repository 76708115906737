import { makeStyles } from "@mui/styles";
import { rem } from "../../utils/css/rem";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.contrast,
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  tyxitIcon: {
    width: rem(138),
    height: rem(20),
    position: "absolute",
    left: rem(41),
    top: rem(40),
    color: theme.palette.text.contrast,
  },
  headphones: {
    width: rem(150),
    height: rem(150),
  },
  title: {
    color: theme.palette.text.contrast,
  },
  button: {
    width: rem(234),
  },
}));

export { useStyles };
