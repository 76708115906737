import React, { memo, useCallback, useContext, useEffect } from "react";
import { CustomDialog } from "../../../library/custom/CustomDialog/CustomDialog";
import { DialogsContext } from "../../../contexts/DialogsContext/DialogsContext";
import { useToggle } from "../../../hooks/useToggle";
import { DialogEnum } from "../../../contexts/DialogsContext/types";
import { CustomTypography } from "../../../library/custom/CustomTypography/CustomTypography";
import { CustomGrid } from "../../../library/custom/CustomGrid/CustomGrid";
import { useStyles } from "./styles";
import { CustomBox } from "../../../library/custom/CustomBox/CustomBox";
import { CustomScrollbar } from "../../../library/custom/CustomScrollbar/CustomScrollbar";
import { CustomIconButton } from "../../../library/custom/CustomIconButton/CustomIconButton";
import { CloseIcon } from "../../../library/icons/CloseIcon";

const MacChipArchitectureExplainingDialog = memo(() => {
  const {
    values: { state },
    actions: { onCloseDialog },
  } = useContext(DialogsContext);

  const { state: isDialogOpen, onSetValue } = useToggle({ initial: false });

  const classes = useStyles();

  useEffect(() => {
    onSetValue({ value: state.macChipArchitectureDialog });
  }, [state.macChipArchitectureDialog]);

  const handleClose = useCallback(() => {
    onCloseDialog(DialogEnum.macChipArchitectureDialog);
  }, []);

  return (
    <CustomDialog
      open={isDialogOpen}
      onClose={handleClose}
      classes={{ paper: classes.container }}
    >
      <CustomIconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </CustomIconButton>
      <CustomScrollbar variant="light" className={classes.scrollbar}>
        <CustomGrid
          container
          direction="column"
          gap={2.5}
          textAlign="center"
          position="relative"
          className={classes.content}
        >
          <CustomTypography
            variant="h2"
            nameSpace="macChipExplaining"
            translation="title"
          />
          <CustomGrid item container direction="column" gap={5}>
            <CustomGrid container item direction="column" alignItems="center" gap={2.5}>
              <CustomTypography
                variant="body2"
                nameSpace="macChipExplaining"
                translation="steps.step1"
                width="fit-content"
                className={classes.step}
              />
              <CustomBox
                component="img"
                src="images/macChipArchitecture/step-1.svg"
                className={classes.img}
              />
            </CustomGrid>
            <CustomGrid container item direction="column" alignItems="center" gap={2.5}>
              <CustomTypography
                variant="body2"
                nameSpace="macChipExplaining"
                translation="steps.step2"
                width="fit-content"
                className={classes.step}
              />
              <CustomBox
                component="img"
                src="images/macChipArchitecture/step-2.svg"
                className={classes.img}
              />
            </CustomGrid>
          </CustomGrid>
        </CustomGrid>
      </CustomScrollbar>
    </CustomDialog>
  );
});

export { MacChipArchitectureExplainingDialog };
