import downloading from "./downloading.json";
import appRedirect from "./appRedirect.json";
import notFound from "./notFound.json";
import macChipExplaining from "./macChipExplaining.json";

export default {
  downloading,
  appRedirect,
  notFound,
  macChipExplaining,
};
