import { makeStyles } from "@mui/styles";
import { rem } from "../../utils/css/rem";

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: "100vh",
    padding: rem(40, 40, 60, 40),
    backgroundImage: "url('/images/download-page-bg.png')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: theme.palette.background.default,
  },
  header: {
    width: "100%",
  },
  tyxitIcon: {
    width: rem(138),
    height: rem(20),
    alignSelf: "flex-start",
  },
  downloadTitle: {
    margin: rem(0, 0, 36, 0),
    width: rem(450),
    textAlign: "center",
  },
  macosChipExplainingButton: {
    position: "absolute",
    top: rem(-70),
  },
}));

export { useStyles };
