import { alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { rem } from "../../../utils/css/rem";

const useStyles = makeStyles((theme) => ({
  thumb: {
    position: "absolute",
    cursor: "pointer",
    borderRadius: rem(10),
  },
  lightThumb: {
    backgroundColor: alpha(theme.palette.common.white, 0.1),
    "&:hover ": {
      backgroundColor: alpha(theme.palette.common.white, 0.1),
    },
    "&:active": {
      backgroundColor: alpha(theme.palette.common.white, 0.1),
    },
  },
  darkThumb: {
    backgroundColor: "#0000001A",
    "&:hover ": {
      backgroundColor: "#0000001A",
    },
    "&:active": {
      backgroundColor: "#0000001A",
    },
  },
  track: {
    backgroundColor: "transparent",
  },
  trackVertical: {
    width: `${rem(8)} !important`,
    right: rem(8),
    padding: rem(8, 0),
    height: "100%",
    top: 0,
  },
  trackHorizontal: {
    height: `${rem(8)} !important`,
    bottom: rem(8),
    margin: rem(0, 8),
    width: "100%",
    display: "none",
  },
}));

export { useStyles };
