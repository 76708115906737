/* eslint-disable max-len */
import React, { memo } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const WindowsIcon = memo((props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <path
        d="M0 2.56849L7.36082 1.56164V8.65068H0V2.56849ZM8.24742 1.41781L18 0V8.58904H8.24742V1.41781ZM0 9.39863H7.36082V16.5082L0 15.4808V9.39863ZM8.24742 9.49315H18V18L8.24742 16.6233V9.49315Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});

export { WindowsIcon };
